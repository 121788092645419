/* eslint no-unreachable:0 */
// Vendor
import React from 'react';
import { Container, Divider, Grid, createMuiTheme } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';
// App
import './App.scss';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Services } from './components/Services';
import { Covid } from './components/Covid';
import { Logo } from './components/Logo';

const theme = createMuiTheme({
	overrides: {
		MuiPaper: {
			root: {
				minHeight: 100,
				marginBottom: 14,
				padding: 14
			}
		}
	}
});
const useStyles = makeStyles(() => ({
	divider: {
		margin: theme.spacing(4)
	},
	root: {
		marginTop: '5vmin'
	}
}));

export default () => {
	const classes = useStyles();
	const showMessage = false;
	return (
		<ThemeProvider theme={ theme }>
			<Helmet>
				<title>Arapuni Electrical</title>
				<meta name="description" content="Arapuni Electrical, South Waikato, servicing: Arapuni, Putaruru, Pukeatua, Tirau, Cambridge, Hamilton, Rotorua, Kihikihi/Te Awamutu, Rural surrounding areas and farms" />
			</Helmet>
			<Container className={ classes.root }>
				<Grid container spacing={ 4 }>
					<MediaQuery maxWidth={ theme.breakpoints.values.sm }>
						<Grid item xs={ 12 }>
							<Logo />
						</Grid>
					</MediaQuery>
					{ showMessage && <Grid item xs={ 12 }>
						<Covid />
					</Grid> }
					<Grid item sm={ 6 } md={ 4 }>
						<About />
					</Grid>
					<MediaQuery minWidth={ theme.breakpoints.values.sm }>
						<Grid item sm={ 6 } md={ 4 }>
							<Logo />
						</Grid>
					</MediaQuery>
					<Grid item sm={ 12 } md={ 4 }>
						<Services />
					</Grid>
				</Grid>
				<Divider className={ classes.divider }/>
				<Contact />
			</Container>
		</ThemeProvider>
	);
};
