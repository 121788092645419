import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Facebook, Mail, Phone } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '5vmin',
		// width: '100%'
	},
	button: {
		width: '100%'
	},
	icon: {
		fontSize: 50,
		marginRight: 20
	}
}));
export const Contact = (props) => {
	const classes = useStyles();
	return (
		<Grid container spacing={ 4 }>
			<Grid item md={ 4 }>
				<Button className={ classes.button } color="" href="tel:+64220344337" >
					<Phone className={ classes.icon } />
					Call or text: 0220344337
				</Button>
			</Grid>
			<Grid item md={ 4 }>
				<Button className={ classes.button } color="primary" href="https://www.facebook.com/pg/arapunielectrical">
					<Facebook className={ classes.icon } />
					Book online at: facebook.com/arapunielectrical
				</Button>
			</Grid>
			<Grid item md={ 4 }>
				<Button className={ classes.button } color="" href="mailto:arapunielectrical@gmail.com">
					<Mail className={ classes.icon } />
					Other enquiries please email: arapunielectrical@gmail.com
				</Button>
			</Grid>
		</Grid>
	);
};
