import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center'
	},
  logo: {
	  display: 'block',
	  margin: 'auto',
	  width: '100%'
  }
}));
export const Logo = (props) => {

	const classes = useStyles();
	return (
		<div className={ classes.root }>
			<a href="https://www.facebook.com/pg/arapunielectrical">
			   <img src="/img/logo.png" alt="Logo" className={ classes.logo } />
			</a>
	   </div>
	);
};
