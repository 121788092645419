import React from 'react';
import { Chip, Paper, Typography } from '@material-ui/core';
import { Room as RoomIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: 7
	}
}));
const chips = [
	'Arapuni',
	'Putaruru',
	'Pukeatua',
	'Tirau',
	'Cambridge',
	'Hamilton',
	'Rotorua',
	'Kihikihi/Te Awamutu',
	'Rural surrounding areas and farms',
];
export const About = (props) => {
	const classes = useStyles();
	return (
		<>
			<Paper>
				<Typography paragraph>
					Arapuni Electrical is a new company based in the south Waikato, operating out of
					Arapuni. We service the greater Waikato region including but not limited to:
				</Typography>
			</Paper>
			<center>
				{ chips.map(lbl => <Chip icon={ <RoomIcon /> } key={ lbl } label={ lbl } className={ classes.chip } />) }
			</center>
		</>
	);
}
