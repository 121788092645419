import React from 'react';
import { Chip, Paper, Typography } from '@material-ui/core';
import { Business, SettingsInputComponent, Build } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Icon from '@mdi/react';
import { mdiTractor, mdiHomeHeart, mdiHomeModern, mdiFactory } from '@mdi/js';

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: 7
	}
}));
const chips = [
	{ icon: <Icon path={ mdiHomeHeart } size={ 1 } />, label: 'Residential' },
	{ icon: <Icon path={ mdiTractor } size={ 1 } /> , label: 'Rural' },
	{ icon: <Business />, label:  'Commercial' },
	{ icon: <Icon path={ mdiFactory } size={ 1 } />, label:  'Light Industrial' },
	{ icon: <SettingsInputComponent /> , label: 'Data cabling' },
	{ icon: <Build />, label: 'Maintenance' },
	{ icon: <Icon path={ mdiHomeModern } size={ 1 } />, label: 'New builds/install' }
];
export const Services = (props) => {
	const classes = useStyles();
	return (
		<>
			<Paper>
				<Typography paragraph>Arapuni Electrical offers a range of services from a registered, qualified electrician and apprentice. Including:</Typography>
			</Paper>
			<center>
				{ chips.map(({icon, label}) => <Chip icon={ icon } key={ label } label={ label } className={ classes.chip } />) }
			</center>
		</>
	);
}
